import React, { memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { usePrepareContactsOrder } from '@/hooks/usePrepareContactsOrder';
import styles from './LandingFooterContacts.module.scss';

export const LandingFooterContacts = memo(() => {
  const { t } = useTranslation([I18N_CODES.common]);

  const contactsOrder = usePrepareContactsOrder();

  return (
    <div className={styles.contactWrapper}>
      <p className={cn(typography.landingH6, 'medium-mb-8')}>
        {t(`${I18N_CODES.common}:footer_contacts`)}
      </p>

      {contactsOrder.map((contact) => (
        <p
          key={contact}
          className={cn(styles.contactAddress, typography.landingTextMain)}
        >
          {t(`${I18N_CODES.common}:contact_us_${contact}`)}
        </p>
      ))}
    </div>
  );
});
