import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export const usePrepareContactsOrder = () => {
  const { subDomain } = useSubDomainContext();

  switch (subDomain) {
    case SubDomains.pl:
      return ['poland'];

    case SubDomains.ua:
      return ['ukraine', 'cyprus', 'poland', 'brazil'];

    case SubDomains.br:
      return ['brazil'];

    default:
      return ['cyprus', 'ukraine', 'poland', 'brazil'];
  }
};
