export const CLASS_NAMES = {
  active: 'is-active',
  invalid: 'is-invalid',
  valid: 'is-valid',
  focused: 'is-focused',
  hidden: 'is-hidden',
  disabled: 'is-disabled',
  accent: 'is-accent',
  loading: 'is-loading',
  required: 'is-required',
  previous: 'is-previous',
  clicked: 'is-clicked',
  sticky: 'is-sticky',
  loaded: 'is-loaded',
  intersecting: 'is-intersecting',
  absolute: 'is-absolute',
  shrunk: 'is-shrunk',
  center: 'is-centered',
  last: 'is-last',
  empty: 'is-empty',
  wide: 'is-wide',
  open: 'is-open',
  unbounded: 'is-unbounded',
  dotaCourse: 'dota-course',
  freeCourse: 'freeCourse',
  white: 'white',
};
