import React, { FC, useCallback } from 'react';
import { LandingFooterContacts } from '@/components/landing/LandingFooterContacts';
import { cn } from '@/lib/classNames';
import { Link, useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { I18N_CODES } from '@/lib/constants/general';
import { ActiveLink } from '@/components/common/ActiveLink';
import { Button } from '@/components/ui/Button';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { GooglePlayButton } from '@/components/nativeApp/GooglePlayButton';
import { AppleStoreButton } from '@/components/nativeApp/AppleStoreButton';
import { LandingFooterWebsiteInfo } from '@/components/landing/LandingFooterWebsiteInfo';
import { LandingFooterDomainActions } from '@/components/landing/LandingFooterDomainActions';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { analyticsSDK } from '@/controllers/analytics';
import { HireGraduateFormSource } from '@/controllers/analytics/generated';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import {
  IconEmojiMilitaryHelmet,
} from '@/components/ui/IconEmojies/IconEmojiMilitaryHelmet';
import { FooterSignInButtons } from '@/components/landing/FooterSignInButtons';
import { LogoFull } from '@/components/common/Logo';
import styles from './LandingFooter.module.scss';

export const LandingFooter: FC = () => {
  const features = useFeatures();
  const isDefaultDomain = useDefaultDomain();
  const { subDomain } = useSubDomainContext();

  const { t } = useTranslation([I18N_CODES.common]);

  const hireFormLink = useTranslateByDomain(
    `${I18N_CODES.common}:hire_form_link`,
  );

  const onHireFormLinkClick = useCallback(() => {
    analyticsSDK.landing.sendHireGraduateFormOpenedEvent({
      source: HireGraduateFormSource.Footer,
    });
  }, []);

  const shouldShowNativeAppButtons = (
    features.isEnabled(features.MobileAppAndroidPrompt)
    || features.isEnabled(features.MobileAppIOSPrompt)
  );

  const shouldShowReviewsPageLink = isDefaultDomain
    && features.isEnabled(features.ReviewsLanding);

  return (
    <footer className={styles.footer}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x pt-56 medium-pt-40 mb-56 medium-mb-64 small-mb-56">
          <div data-qa="footer-logo" className={cn('cell large-3 medium-8', styles.footerLogo)}>
            <Link href={ROUTES.home}>
              <a className={cn(styles.logoLink, 'small-mb-40')}>
                <LogoFull className={styles.logo} />
              </a>
            </Link>
          </div>

          <div className={cn('cell large-2 flex-container flex-dir-column align-top', styles.footerLinks)}>
            <ActiveLink href={ROUTES.price}>
              <Button
                data-qa="footer-price-page-link"
                href={ROUTES.price}
                text={t(`${I18N_CODES.common}:price_page_link`)}
                size={Button.size.Large}
                mode={Button.mode.TransparentDark}
                className={styles.linkButton}
              />
            </ActiveLink>

            {shouldShowReviewsPageLink && (
              <ActiveLink href={ROUTES.reviews}>
                <Button
                  href={ROUTES.reviews}
                  text={t(`${I18N_CODES.common}:reviews_page_link`)}
                  size={Button.size.Large}
                  mode={Button.mode.TransparentDark}
                  className={styles.linkButton}
                />
              </ActiveLink>
            )}

            <ActiveLink href={ROUTES.team}>
              <Button
                data-qa="footer-company-page-link"
                href={ROUTES.team}
                text={t(`${I18N_CODES.common}:company_page_link`)}
                size={Button.size.Large}
                mode={Button.mode.TransparentDark}
                className={styles.linkButton}
              />
            </ActiveLink>

            {features.isEnabled(features.BlogLanding)
              && isDefaultDomain
              && (
                <Button
                  href={ROUTES.blog}
                  text={t(`${I18N_CODES.common}:blog_page_link`)}
                  size={Button.size.Large}
                  mode={Button.mode.TransparentDark}
                  className={styles.linkButton}
                />
              )}

            {features.isEnabled(features.PolishEntityMergeAnnouncement)
              && subDomain === SubDomains.pl
                && (
                  <Button
                    text={t(`${I18N_CODES.common}:announcement_page_link`)}
                    size={Button.size.Large}
                    mode={Button.mode.TransparentDark}
                    className={styles.linkButton}
                    href="https://mate.academy/lp/announcement/elg-100"
                    target="_blank"
                    rel="noreferrer"
                  />
                )}
          </div>

          <div className={cn(
            'cell large-3 flex-container flex-dir-column align-top small-mb-56',
            styles.footerLinks,
          )}
          >
            {hireFormLink && (
              <Button
                href={hireFormLink}
                text={t(`${I18N_CODES.common}:hire_page_link`)}
                size={Button.size.Large}
                mode={Button.mode.TransparentDark}
                className={styles.linkButton}
                target="_blank"
                onClick={onHireFormLinkClick}
              />
            )}

            {features.isEnabled(features.FreeStudyingForCombatants)
              && isDefaultDomain
              && (
                <Button
                  className={styles.linkButton}
                  href={t(`${I18N_CODES.common}:free_courses_for_combatants_article_link`)}
                  target="_blank"
                  renderRigthIcon={() => (
                    <IconEmojiMilitaryHelmet
                      size={24}
                      className={styles.icon}
                    />
                  )}
                  text={t(`${I18N_CODES.common}:free_courses_for_combatants_link`)}
                  data-qa="footer-free-courses-for-combatants-button"
                  size={Button.size.Large}
                  mode={Button.mode.TransparentDark}
                />
              )}

            <Button
              href="https://career.mate.academy/"
              target='_blank'
              text={t(`${I18N_CODES.team}:join_to_us`)}
              size={Button.size.Large}
              mode={Button.mode.TransparentDark}
            />
          </div>

          <div className={cn(styles.footerAuth, 'cell large-3 large-offset-1 medium-4')}>
            <FooterSignInButtons />
          </div>
        </div>

        {shouldShowNativeAppButtons && (
          <div className={cn(styles.mobileAppLinks, 'mb-24')}>
            {features.isEnabled(features.MobileAppIOSPrompt) && (
              <AppleStoreButton source="landing_footer" />
            )}

            {features.isEnabled(features.MobileAppAndroidPrompt) && (
              <GooglePlayButton source="landing_footer" />
            )}
          </div>
        )}

        <LandingFooterDomainActions />

        <LandingFooterContacts />

        <LandingFooterWebsiteInfo />
      </div>
    </footer>
  );
};
